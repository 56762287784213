@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

.switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #D9E6E8;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    box-shadow: 0px 1px 2px 0px rgba(22, 42, 100, 0.12);
}

input:checked+.slider {
    background: rgba(93, 146, 152, 1);
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 12px;
}

.slider.round:before {
    border-radius: 50%;
}

.slider-supportingtext {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgba(131, 131, 173, 1);
}

/* text */
#ph {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    text-underline-position: 'from-font';
    text-decoration-skip-ink: none;
    color: #8383AD;
}