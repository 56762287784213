// TEXT

.protocol-bold-text {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #000;
}

.protocol-text {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: #166428;
}

.vertical-line {
    width: 4px;
    height: 40px;
    border: 1px solid #3AD45E;
    background-color: #3AD45E;
    margin-right: 16px;
}