#popup {

    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(24, 43, 46, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.boxElements {
    width: 50%;
    margin: 25% auto;
    z-index: 10;
}

.header {
    padding: 24px 32px 24px 32px;
    gap: 0px;
    border: 0px 0px 1px 0px;
    opacity: 0px;
}

.popup-content {
    background-color: #fff;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    max-height: 80vh;
    overflow-y: auto;
}

.popupHeader {
    font-family: 'Manrope';
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.02em;
    text-align: left;
    padding: 24px 32px 24px 32px;
    gap: 0px;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: space-between;

}