.checkbox-container {
    display: flex;
    align-items: center;
    margin-right: 15px;
  }
  
  .checkbox-container input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid #5d9298;
    border-radius: 4px;
    outline: none;
    margin-right: 8px;
    cursor: pointer;
    position: relative;
    background-color: white; /* Background predefinito */
  }
  
  .checkbox-container input[type="checkbox"]:checked {
    background-color: #4D797F; /* Cambia il background quando è selezionata */
    border-color: #4D797F; /* Cambia anche il bordo */
  }
  
  .checkbox-container input[type="checkbox"]::after {
    content: "";
    position: absolute;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%) rotate(45deg) scale(0);
    transition: transform 0.1s ease-in-out;
  }
  
  .checkbox-container input[type="checkbox"]:checked::after {
    transform: translate(-50%, -60%) rotate(45deg) scale(1); /* Mostra il tick */
  }
  
  .checkbox-container label {
    cursor: pointer;
  }
  
  .checkboxLabel {
    font-family: "Manrope";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }
  