.rowProtDetails {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.details {
    flex: 1;
    text-align: left;
}

.mainBox {
    background-color: #F2F7F7;
}

.titleDetails {
    font-family: "Manrope", sans-serif;
    font-size: 16px;
    color: #314D51;
    font-weight: 700;
    display: inline-block;
    margin-right: 5px;
}