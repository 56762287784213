/* Box principale */
.pre-box {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    max-width: 800px;
}

/* Box certificazione */
.generic-box {
    width: 480px;
    min-height: 270px;
    padding: 20px;
}

.reduce:hover {
    transform: scale(0.98); 
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15); 
}

/* Titolo principale */
.generic-box .title {
    font-family: 'Manrope', sans-serif;
    font-size: 30px; /* Titolo più grande */
    font-weight: 700;
    color: #37565A;
    margin-bottom: 15px;
}

/* Sottotitolo */
.generic-box .description {
    font-family: 'Manrope', sans-serif;
    font-size: 20px; /* Sottotitolo più piccolo */
    font-weight: 500;
    color: #6F8B8C;
    margin-bottom: 25px;
}

/* Contenuto della certificazione */
.generic-content {
    padding: 10px 0;
}

