@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.table-content {
    border-top: 1px solid #D9E6E8;
}

/* TEXT */
.table-head {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: #5D9298;
}

.table-content-text {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.sub-options {
    display: flex;
    flex-direction: row;
}