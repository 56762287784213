@keyframes shine {
  0% {
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0) 100%);
    transform: translateX(-100%);
  }
  100% {
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0) 100%);
    transform: translateX(100%);
  }
}

.logo-container-animation {
  position: relative;
  display: flex;
  overflow: hidden;
  background-color: #5D9298;
  width: 180px;
  height: 80px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
}

.logo-container-animation svg {
  position: relative;
  z-index: 1;
}

.logo-container-animation::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0) 100%);
  animation: shine 2s infinite;
  z-index: 2;
}
