/* RadioButtonGroup.css */
.radio-group {
  display: flex;
  align-items: center;
}

.radio-container {
  display: flex;
  align-items: center;
  margin-right: 15px;
  /* Spazio tra i radio button */
}

.radio-container input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #5D9298;
  border-radius: 50%;
  outline: none;
  margin-right: 8px;
  cursor: pointer;
  position: relative;
}

.radio-container input[type="radio"]::before {
  content: '';
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #5D9298;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.1s ease-in-out;
}

.radio-container input[type="radio"]:checked::before {
  transform: translate(-50%, -50%) scale(1);
}

.radio-container label {
  cursor: pointer;
}

.radioLabel {
  font-family: 'Manrope';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

}