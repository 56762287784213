@import '../../utils/index.module.scss';

.headings,
#users-table thead th {
    background-color: #4D797F;
    color: #FFF;
    text-align: left;
    padding: 16px 8px 16px 8px;
    font-size: 12px;
    text-transform: uppercase;
}

#users-table tbody td {
    padding: 16px 8px 16px 8px;
    text-align: left;
    font-family: 'Manrope';
}

.react-datepicker-wrapper input {
    width: 100%;
    border: 1px solid rgba(242, 242, 250, 1);
    border-radius: 8px;
}

.headings .MuiDataGrid-columnHeaderTitle {
    text-align: center;
}

.rowWhite {
    background-color: #fff;
    color: #434366;

}

.labelStatus {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
}

.labelOffice {
    padding: 8px;
    border-radius: 6px;
}

.labelFunz {
    border: 1px solid #B1CAFC;
    padding: 8px;
    border-radius: 6px;
}

.activeLabel {
    padding: 8px;
    background-color: rgba(236, 253, 243, 1);
    border-radius: 6px;
}

.deactiveLabel {
    padding: 8px;
    background-color: #FFC4C2;
    border-radius: 6px;
}

.textBlue {
    color: #00457C;
    font-weight: bold;
}

.ManropeBold {
    font-family: 'Manrope';
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.alignCell {
    display: flex;
    justify-content: center !important;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: #fff;
}

.text-uppercase {
    text-transform: uppercase;
}

.label-input {

    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container input {
    border-radius: 8px;
    border: 1px solid #F2F2FA;
    padding: 12px;
    color: #00457C;
    font-size: 14px;
}

.fieldsetTitle {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
}

.boxFieldsetTitle {
    border-bottom: 1px solid #F2F2FA;
}

.reviewPermissionTable {
    width: 100%;
}

.reviewPermissionTable tr {
    border: 1px solid #E4E4F7;
}

.reviewPermissionTable tr td {
    text-align: left;
    padding: 12px 16px 12px 16px;
}

.reviewPermissionTable .value {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    color: #00457C

}

.reviewPermissionTable .label {
    font-family: Manrope;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: #8383AD;
    text-transform: uppercase;
    width: 120px;
    max-width: 120px;
}
