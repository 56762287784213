// TEXT

.cb-box {
    display: flex;
    align-items: center;
    gap: 48px;
    border-radius: 20px;
    padding: 16px 24px 16px 24px;
    background-color: #fff;
}

.cb-bold-text {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: #000;
}

.cb-text {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #166428;
}