/* Box principale */
.pre-box {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    max-width: 800px;
}

/* Box certificazione */
.cert-box {
    padding: 20px;
}

/* Titolo principale */
.cert-box .title {
    font-family: 'Manrope', sans-serif;
    font-size: 30px; /* Titolo più grande */
    font-weight: 700;
    color: #37565A;
    margin-bottom: 10px;
}

/* Sottotitolo */
.cert-box .subtitle {
    font-family: 'Manrope', sans-serif;
    font-size: 22px; /* Sottotitolo più piccolo */
    font-weight: 500;
    color: #6F8B8C;
    margin-bottom: 20px;
}

/* Contenuto della certificazione */
.cert-content {
    padding: 10px 0;
}

/* Layout dei dati */
.cert-box .cert-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 0;
}

/* Testo marcato */
.cert-box .content-bold {
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    color: #314D51;
    font-weight: 600;
}

/* Testo del contenuto */
.cert-box .content {
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    color: #4D797F;
}

/* Linea orizzontale di separazione */
.cert-box .line {
    border-bottom: 1px solid #D9E6E8;
    margin: 12px 0;
}

/* Posizionamento dell'immagine */
.cert-box .logo-container {
    flex: 0.2;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
