@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

.text-area-value {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: rgba(98, 98, 138, 1);
    text-decoration: none;
}

.text-area-container text-area:disabled {
    color: rgba(116, 134, 170, 1);
    background-color: rgba(242, 242, 250, 1) !important;
}

.text-area-container text-area::placeholder {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    text-decoration: none;
    color: rgba(116, 134, 170, 1);
}

.text-area-container {
    border: 1px solid;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    resize: none;
    outline: 0;
    padding: 8px 12px 8px 12px;
    gap: 8px;
    width: 100%;
}

.text-area-label {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: #434366;
}

.text-area-supporting-text {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
}