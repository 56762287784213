.numbPages {
    display: inline-block;
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 700; 
    line-height: 16px; 
    text-align: center;
    cursor: pointer;
    padding: 4px 6px;  
    transition: background-color 0.3s ease;
}

.numbPages:hover {
    background-color: #f0f0f0; 
}

.paginationIcon {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    padding: 4px;
}

.textNumberResults {
    font-family: 'Manrope';
    font-size: 14px;
    text-align: left;
}

.numberResults {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 700;
    text-align: left;
}

.space-between {
    justify-content: space-between;
}

.numbPages.bg-brandPrimary-100 {
    background-color: #e0f7fa; 
    color: #00796b;  
    border-radius: 4px;  
}
