.toolBox {
    flex: 0.5;
    display: flex;
    justify-content: left;
    flex-direction: column;
}

.boxTitle {
    padding: 15px 25px;
    background: #4D797F;
    width: 100%;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Manrope';
    text-align: left;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    text-transform: uppercase;
}

.boxBody {
    width: 100%;
    background: #F2F7F7;
}

.bigButtonUtils {
    padding: 20px;
    background: #F5FDFF;
    border-radius: 24px;
}

.bodyLabel {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Manrope';
    color: #37565A;
}