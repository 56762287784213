.reporting-content {
    width: auto;
    height: 1,326px;
    padding: 24px 24px 40px 24px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    gap: 24px;
    // opacity: 0px;
    background-color: #F2F7F7;
}

.reporting-header {
    width: auto;
    height: 50px;
    padding: 16px 24px 16px 24px;
    gap: 0px;
    border: 0px 0px 1px 0px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    // opacity: 0px;
    background-color: #4D797F;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reporting-header .title {
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    text-transform: uppercase;
    color: #FFF;
}

/* STATE */

.state-box {
    padding: 12px;
    border: 1px solid #314D51;
    border-radius: 12px;
    background-color: #74A4A9;
}

.state-box .state-main {
    font-family: "Roboto";
    font-size: 18px;
    font-weight: 600;
    line-height: 12px;
    text-align: left;
    color: #F2F7F7;
}

.state-box .state-sub {
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 400;
    line-height: 12px;
    max-width: 1000px;
    text-align: left;
    color: #F2F7F7;
}

.state-box .state-note {
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
    line-height: 12px;
    max-width: 1000px;
    text-align: left;
    color: #F2F7F7;
}

/* LISTING */
.innerstate-box {
    border-radius: 15px;
    width: 100px;
    padding: 4px;
    text-align: center;
}

.innerstate-box .text {
    font-family: 'Manrope';
    font-size: 16px;
    font-weight: 600;
    line-height: 12px;
    color: #F2F7F7;
}