@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

.p-multiselect.p-component.p-inputwrapper {
    background-color: white;
    border: 1px solid #CBCBEB;
    border-radius: 8px;
    height: 44px;
}

.p-multiselect.p-component.p-inputwrapper.p-disabled {
    background-color: #F2F2FA;
    border: 1px solid #CBCBEB;
    border-radius: 8px;
    height: 44px;
    opacity: 1;
}

.p-multiselect.p-component.p-inputwrapper.p-inputwrapper-focus {
    box-shadow: none;
}

.p-multiselect-label {
    font-weight: 500;
    font-family: 'Manrope';
    color: rgba(98, 98, 138, 1);
    font-size: 12px;
}

/* .p-component .p-multiselect-label {
    font-size: 12px;
} */

.p-multiselect-empty-message {
    font-weight: 500;
    font-family: 'Manrope';
    color: #323252;
}

.p-multiselect-label.p-placeholder {
    font-size: 14px;
    line-height: 20px;
    color: #ADADD6;
    font-family: 'Manrope';
    font-weight: 500;
}

.p-multiselect-panel {
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(22, 42, 100, 0.12);
    border-radius: 12px;
    padding: 8px;

}

.p-multiselect-panel .p-multiselect-item, .p-multiselect-select-all-label {
    color: #323252;
    font-weight: 00;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    font-family: 'Manrope';
}

.p-multiselect-select-all-label {
    color: #323252;
    font-weight: 00;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Manrope';
}

.p-multiselect-panel .p-multiselect-item.p-highlight {
    background-color: white;
    border-radius: 12px;
}

.p-multiselect-panel .p-multiselect-item:hover {
    background-color: #F2F7F7;
    border-radius: 12px;
}

.p-multiselect-header {
    background-color: white;
}

.p-multiselect-header:hover {
    background-color: #E6E7F9;
    border-radius: 12px;
}

.p-multiselect-panel .p-checkbox-box {
    width: 20px;
    height: 20px;
    border: 1px solid #ADADD6;
    border-radius: 4px;
    background-color: white;
    position: relative;
    cursor: pointer;
}

.p-multiselect-panel .p-checkbox-box[data-p-highlight="true"] {
    background-color: #4D797F;
    border-color: #4D797F;
    box-shadow: 0px 0px 0px 4px rgba(146, 92, 255, 0.16);
}

/* error text style */
.multiselect-error-text {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: 'rgba(253, 102, 91, 1)';
}