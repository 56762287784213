.attachments-container {
    display: flex;
    justify-content: space-around;
}

.attachments-container .box {
    background-color: #F2F7F7;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
    max-width: 200px;
}

.attachments-container .box:hover {
    cursor: pointer;
    background-color: #D9E6E8;
}

.attachments-container .box .label {
    font-family: 'Manrope', sans-serif;
    font-size: 16px; /* Sottotitolo più piccolo */
    font-weight: 500;
    color: #6F8B8C;
}