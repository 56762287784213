.radio-label {
  color: #303030;
  font-size: 14px;
  font-weight: 400;
  margin-right: 7px;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

.radio-input {
  margin: 0;
  visibility: hidden;
}

.radio-input:checked + span {
  border: 1px solid #5D9298;
}

.radio-input:checked + span::after {
  opacity: 1;
}

.custom-radio {
  position: relative;
  cursor: pointer;
  width: 18px;
  height: 18px;
  border: 1px solid #5D9298;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}

.custom-radio::after {
  content: '';
  width: 8px;
  height: 8px;
  background: #5D9298;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  /* Regola per centratura precisa */
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s;
}

.radio-input:checked + .custom-radio::after {
  opacity: 1;
}
