.popup {
    width: auto;
    height: 1,326px;
    padding: 24px 24px 32px 24px;
    margin: 24px;
    border-radius: 20px;
    gap: 24px;
    background-color: #F2F7F7;
}

.subtitle {
    font-family: 'Manrope';
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    color: #182B2E;
}