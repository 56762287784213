.labelStatus {
    display: inline-block;
    padding: 8px;
    border-radius: 8px;
    text-transform: uppercase;
    color: #fff;
    font-family: 'Manrope';
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;

}

.evasa {
    background: #3AD45E;
}

.nonEvasa {
    background: #FF443D;
}