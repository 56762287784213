.protocol-content {
    width: auto;
    height: 1,326px;
    padding: 24px 24px 40px 24px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    gap: 24px;
    // opacity: 0px;
    background-color: #F2F7F7;
}

.protocol-header {
    width: auto;
    height: 50px;
    padding: 16px 24px 16px 24px;
    gap: 0px;
    border: 0px 0px 1px 0px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    // opacity: 0px;
    background-color: #4D797F;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.protocol-header .title {
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    text-transform: uppercase;
    color: #FFF;
}