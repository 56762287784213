@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

#title-eval-tab {
    font-family: 'Manrope';
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
}

.result {
    border-radius: 4px;
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
}

.positive {
    color: #166428;
    background-color: #E6F9EA;
}

.negative {
    color: #940500;
    background-color: #FFEBEB;
}