/* TEXT */

.rid-title {
    font-family: 'Manrope';
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: center;
    text-underline-position: 'from-font';
    text-decoration-skip-ink: none;
}

.rid-right-title {
    font-family: 'Manrope';
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: center;
    text-underline-position: 'from-font';
    color: #314D51;
}

/* STATUS COMPONENT */
.tit-1-status {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    text-underline-position: 'from-font';
    text-decoration-skip-ink: none;
    color: #4D797F;
}

.tit-2-status {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    text-underline-position: 'from-font';
    text-decoration-skip-ink: none;
}