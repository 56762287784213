.labelTab {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;

}

.label-input {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}

.headings,
#customers-table thead th {
    background-color: #4D797F;
    color: #FFF;
    text-align: left;
    padding: 16px 8px 16px 8px;
    font-size: 12px;
    text-transform: uppercase;
}

#customers-table tr:nth-child(odd) {
    background-color: #F2F7F7;
}

#customers-table tr {
    background-color: #fff;
    color: '#4D797F';
}

#customers-table tbody td {
    padding: 16px 8px 16px 8px;
    text-align: left;
    font-family: 'Manrope';
}


#boxAnagrafica .headingAccordion,
#boxRubrica .headingAccordion {
    margin: 0;
    background-color: #4D797F;
}

#boxAnagrafica .headingAccordion {
    padding: 16px 24px 16px 24px;
}


#boxAnagrafica .MuiAccordionSummary-content {
    margin: 0
}

.headings,
#tableReview thead th {
    background-color: #4D797F;
    color: #ADADD6;
    text-align: left;
    padding: 16px 8px 16px 8px;
    font-size: 12px;
    text-transform: uppercase;
}

#tableReview tbody td {
    padding: 16px 8px 16px 8px;
    text-align: left;
    font-family: 'Manrope';
}

.boxDetails {
    padding: 24px;
}

.boxCompanyTech {
    padding: 32px 24px 32px 24px;
    gap: 24px;
    border-radius: 12px;
}


.headerReview {
    //styleName: Text/xl/Semibold;
    font-family: 'Manrope';
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: rgba(24, 43, 46, 1);
    padding: 16px 24px 16px 24px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    padding-left: 24px;
    padding-right: 24px;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}


.boxTableReview {
    padding: 16px 24px 16px 24px;
}

.rowDetails:not(:last-child) {
    border-bottom: 1px solid rgba(217, 230, 232, 1)
}

.rowDetails td {
    padding: 12px 0px;
    color: rgba(55, 86, 90, 1);

}

.labelNorm {
    display: inline-block;
    background: #3AD45E;
    padding: 8px;
    border-radius: 8px;
    text-transform: uppercase;
    color: #fff;
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;

}

.clientCode {
    font-family: 'Manrope';
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
}

.headingAccordion {
    border-top-left-radius: 10px;
}
