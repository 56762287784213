@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.darkBG {
    background-color: rgba(0, 0, 0, 0.2);
    width: 100vw;
    height: 100vh;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
  }
  
  .centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
  
  #rappLegPopup .modal {
    width: 1200px;
    height: auto;
    padding: 16px 0px 24px 0px;
    gap: 24px;
    background: white;
    color: white;
    z-index: 0;
    border-radius: 16px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  }
  
  .modalHeader {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: space-between;
  }
  
  .heading {
    color: #333747;
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  }

  .subtitle {
    color: #333747;
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
  }

  .col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .input-style {
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-color: #d9e6e8;
    border-radius: 8px;
    background-color: white;
    height: 40px;
    width: 216px;
    padding: 0px 12px 0px 12px;
    gap: 8px;
  }

  .title-style {
    color: #5D9298;
    font-family: 'Manrope';
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
  }

  .modalContainerRL {
    margin-top: 20px;
  }
  
  .modalActions {
    position: absolute;
    bottom: 2px;
    margin-bottom: 10px;
    width: 100%;
  }
  
  .actionsContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .closeBtn {
    cursor: pointer;
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 8px;
    border: none;
    font-size: 18px;
    color: #2c3e50;
    background: white;
    transition: all 0.25s ease;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
  }
  
  .closeBtn:hover {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    transform: translate(-4px, 4px);
  }
  
  .deleteBtn {
    margin-top: 10px;
    cursor: pointer;
    font-weight: 500;
    padding: 11px 28px;
    border-radius: 12px;
    font-size: 0.8rem;
    border: none;
    color: #fff;
    background: #ff3e4e;
    transition: all 0.25s ease;
  }
  
  .deleteBtn:hover {
    box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6);
    transform: translateY(-5px);
    background: #ff3e4e;
  }
  
  .cancelBtn {
    margin-top: 10px;
    cursor: pointer;
    font-weight: 500;
    padding: 11px 28px;
    border-radius: 12px;
    font-size: 0.8rem;
    border: none;
    color: #2c3e50;
    background: #fcfcfc;
    transition: all 0.25s ease;
  }
  
  .cancelBtn:hover {
    box-shadow: none;
    transform: none;
    background: whitesmoke;
  }