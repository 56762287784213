.pre-box {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Ombra per profondità */
    max-width: 800px;
}

.profile-box {
    // border: 1px solid #D9E6E8;
    border-radius: 15px;
    background-color: #F2F7F7;
    // padding: 30px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

/* Titolo del box */
.profile-box .title {
    font-family: 'Manrope', sans-serif;
    font-size: 26px;
    font-weight: 700;
    color: #ffffff;
    background-color: #37565A;
    padding: 16px 24px;
    // margin: -30px -31px 20px -31px;
    text-transform: none;
    border-radius: 15px 15px 0 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Contenuto del profilo */
.profile-content {
    padding: 15px;
}

/* Layout dei dati */
.profile-box .profile-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 0;
}

/* Testo del contenuto marcato */
.profile-box .content-bold {
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    color: #314D51;
    font-weight: 600;
}

/* Testo del contenuto */
.profile-box .content {
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    color: #4D797F;
}

/* Linea orizzontale di separazione */
.profile-box .line {
    border-bottom: 1px solid #D9E6E8;
    margin: 12px 0; /* Spazio tra la linea e le voci */
}

/* Layout per la separazione dei dati */
.profile-box .profile-data div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-align: left;
}
