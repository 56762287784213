@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

.input-value {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #62628A;
    text-decoration: none;
}

.input-container input:disabled {
    color: rgba(116, 134, 170, 1);
    background-color: rgba(242, 242, 250, 1) !important;
}

.input-container input::placeholder {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    text-decoration: none;
    color: rgba(116, 134, 170, 1);
}

.input-container {
    border: 1px solid;
    border-radius: 8px;
    height: 44px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    padding: 0px 12px;
    gap: 8px;
    width: 100%;
}

.input-label {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: #314D51;
    font-weight: bold;
}

.input-supporting-text {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
}

.label-search-code {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;

}