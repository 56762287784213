@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.container-li-ca {
    width: 100%;
    height: auto;
    border-top: 1px solid #D9E6E8;
    padding: 0px 8px 0px 8px;
    display: flex;
}

.container-li-ca .code {
    flex: 64px;
    max-width: 64px;
    padding: 16px, 8px, 16px, 8px;
    text-align: left;
}

.container-li-ca .code span {
    font-family: 'Manrope';
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    color: #434366;
    text-align: center;
}

.container-li-ca .content {
    display: flex;
    flex: 1;
    align-items: flex-start;
}

.container-li-ca .paragraph-div {
    max-width: '50%';
    word-wrap: break-word;
}

.container-li-ca .options {
    flex: 5%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.container-li-ca .options .sub-options {
    display: flex;
    flex-direction: row;
}

/* TEXT */
.title {
    font-family: 'Manrope';
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
}

.sub-notbold {
    font-family: 'Manrope';
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: #4D797F;
}

.sub-bold {
    font-family: 'Manrope';
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;
    color: #4D797F;
}

.paragraph {
    font-family: 'Manrope';
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #4D797F;
}